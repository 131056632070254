import {ref, watch, computed, reactive} from '@vue/composition-api'
import store from '@/store'

// Notification
import {useToast} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {isEmpty} from "@core/utils/filter";

export default function salesorderUseList(config) {
    // Use toast
    const toast = useToast()

    const refListTable = ref(null)

    // Table Handlers
    const tableColumns = [
        {key: '#', label: '#'},
        // { key: 'id', label: 'ID', sortable: true },
        {key: 'order_no', label: '编号', sortable: true},
        // { key: 'type_id', label: '业务类型 ', sortable: true },
        // { key: 'channel_id', label: '渠道ID', sortable: true },
        {key: 'channel_name', label: '渠道'},
        // { key: 'channel_contact_id', label: '渠道联系人ID', sortable: true },
        // { key: 'channel_contact_name', label: '渠道联系人姓名', sortable: true },
        // { key: 'channel_contact_mobile', label: '渠道联系电话', sortable: true },
        // { key: 'channel_order_no', label: '渠道合同编号', sortable: true },
        // { key: 'trade_type', label: '贸易方式', sortable: true },
        // { key: 'company_id', label: '主体ID', sortable: true },
        // { key: 'company_name', label: '主体名称', sortable: true },
        // { key: 'sales_id', label: '销售员ID', sortable: true },
        {key: 'sales_time', label: '销售日期', sortable: true},
        {key: 'store_name', label: '销售店铺'},
        {key: 'sales_warehouse', label: '销售仓库'},
        {key: 'sales_name', label: '销售员'},
        // { key: 'sales_mobile', label: '销售员联系电话', sortable: true },
        // { key: 'contract_id', label: '合同ID', sortable: true },
        // { key: 'contract_no', label: '合同编号', sortable: true },
        // { key: 'contract_type', label: '合同类型', sortable: true },
        // { key: 'invoice_type', label: '发票类型', sortable: true },
        // { key: 'payment_method', label: '付款方式', sortable: true },
        // { key: 'need_advance', label: '是否需要预付款', sortable: true },
        // { key: 'settlement_method', label: '结算方式', sortable: true },
        // { key: 'settlement_times', label: '结算次数', sortable: true },
        // { key: 'delivery_type', label: '物流方式  delivery_method_sale', sortable: true },
        /*{ key: 'extra_cost_subtotal', label: '附加费小计', sortable: true },*/
        {key: 'total_receive', label: '应收金额', sortable: true},
        {key: 'grossProfit', label: '毛利', sortable: true},
        {key: 'salesCostAll', label: '销售权毛利', sortable: true},
        // { key: 'total_received', label: '已付金额', sortable: true },
        {key: 'total_qty', label: '订单总数量', sortable: true},
        {key: 'total_delivery_qty', label: '已发货数量', sortable: true},
        {key: 'statement_subtotal', label: '已结算金额', sortable: true},
        {key: 'status', label: '订单状态', sortable: true},
        {key: 'remark', label: '备注'},
        {key: 'attach', label: '附件'},
        // { key: 'state', label: '状态', sortable: true },
        {key: 'source', label: '订单来源', sortable: true},
        {key: 'add_time', label: '创建', sortable: true},
        // { key: 'creator', label: '创建人', sortable: true },
        {key: 'modify_time', label: '修改', sortable: true},
        // { key: 'updator', label: '修改人', sortable: true },
        //   { key: 'is_sendbi', label: '是否发送到bi  null表示不发送  1表示发送', sortable: true },
        //   { key: 'sendbi_time', label: '操作发送BI时间', sortable: true },
        {key: 'is_statement', label: '对账单'},
        {key: 'is_sendbi', label: '终审状态'},
        {key: 'sendbi_time', label: '终审时间'},
        {key: 'sendbier', label: '终审人'},
        {key: 'price_check', label: '售价审核'},
        {key: 'price_check_status', label: '售价审核'},
        {key: 'actions', label: '操作'},

    ]

    const start = ref(1)
    const limit = ref(10)
    const limitOptions = [10, 25, 50, 100]
    const listTotals = ref(0)
    const searchKey = ref('')
    const orderBy = ref('order_id')
    const isSortDirDesc = ref(true)

    const dataMeta = computed(() => {
        const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
        return {
            from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
            to: limit.value * (start.value - 1) + localItemsCount,
            of: listTotals.value,
        }
    })
    /*
      const refetchData = () => {
          refListTable.value.refresh()
      }

      watch([start, limit, searchKey], () => {
        refetchData()
      })*/

    const searchList = (ctx, callback) => {
        const condition = {...store.getters['salesorder/getCondition']}
        const storeArrays = []
        const warehouseArrays = []
        const storeArray = isEmpty(condition.storeArrays)?[]:condition.storeArrays
        const warehouseArray = isEmpty(condition.warehouseArray)?[]:condition.warehouseArray
        for (let i = 0; i < storeArray.length; i++) {
            storeArrays.push(storeArray[i].value)
        }
        for (let i = 0; i < warehouseArray.length; i++) {
            warehouseArrays.push(warehouseArray[i].value)
        }
        store
            .dispatch('salesorder/search', {
                search_key: searchKey.value,
                productCode: condition.productCode,
                productName: condition.productName,
                productId: condition.productId,
                salesId: condition.salesId,
                orderNo: condition.orderNo,
                orderId: config.order_id,
                companyId: condition.companyId,
                orderStatus: condition.orderStatus != null ? condition.orderStatus.value : null,
                source: condition.source != null ? condition.source.value : null,
                qtyIsZero: condition.qtyIsZero != null ? condition.qtyIsZero.value : null,
                moneyIsLowZero: condition.moneyIsLowZero != null ? condition.moneyIsLowZero.value : null,
                isSendbiStr: condition.isSendbiStr != null ? condition.isSendbiStr.value : null,
                addTime: condition.addTime,
                salesTime: condition.salesTime,
                start: start.value,
                limit: limit.value,
                order_by: orderBy.value,
                order_desc: isSortDirDesc.value === true ? 'desc' : '',
                channelId: condition.channelName == null?null:condition.channelName.value,
                storeIds: storeArrays.join(','),
                warehouseIds: warehouseArrays.join(','),
                isStatement: condition.isStatement != null ? condition.isStatement.value : null,
                purchaseTeamId:isEmpty(condition.purchaseTeamId)?null:condition.purchaseTeamId.value,
            })
            .then(response => {
                const data = response.data.data
                console.log(data)
                const list = data.ext.list
                listTotals.value = data.ext.totals

                callback(list)
            })
            .catch((e) => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: '销售订单列表获取错误',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
            })
    }

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    return {
        searchList,
        tableColumns,
        limit,
        start,
        listTotals: listTotals,
        dataMeta,
        limitOptions,
        searchKey,
        orderBy,
        isSortDirDesc,
        refListTable,
        // refetchData,
        // Extra Filters
    }
}
