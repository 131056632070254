<template>

  <div>

    <!-- Table Container Card -->

    <b-card>
      <div>
        <b-table
            sticky-header
            noCollapse
            ref="refListTable"
            class="position-relative"
            :items="searchList"
            responsive
            :fields="tableColumns"
            primary-key="id"
            :sort-by.sync="orderBy"
            show-empty
            empty-text="未找到记录"
            :sort-desc.sync="isSortDirDesc"
        >
          <!--                {{test(row.item.order_id)}}-->
          <template #cell(ext)="data">
            {{ data.item.ext.code ? data.item.ext.code : '' }}
          </template>

          <template #cell(name)="data">
            [#{{ data.item.product_id }}]{{ data.item.name }}
          </template>

          <template #cell(qty)="data">
            {{ data.item.ext.qty }}
          </template>

          <template #cell(salesreturnqty)="data">
            <b-badge
                pill
                :variant="`light-warning`"
            >
              {{ data.item.ext.salesReturnQty }}
            </b-badge>
          </template>

        </b-table>
      </div>
    </b-card>

  </div>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BFormCheckbox, BModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted, reactive, toRefs } from '@vue/composition-api'
import mainTableItemUseList from './mainTableItemUseSumList'
import salesorderitemStore from './salesorderitemStore'
import {avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor} from '@core/utils/filter'
import Ripple from "vue-ripple-directive";
import {useToast} from "vue-toastification/composition";
import StockOccupiedList from "@/views/apps/stockoccupied/StockOccupiedList.vue";
export default {
  components: {
    StockOccupiedList,
    BModal,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    order_id: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      stockOccupied:{},
    }
  },
  setup(props) {
    // Register module
    if (!store.hasModule('salesorderitem')) store.registerModule('salesorderitem', salesorderitemStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('salesorderitem')) store.unregisterModule('salesorderitem')
    })
    const toast =  useToast()

    const
      itemList
    = mainTableItemUseList({
      order_id: props.order_id,
    })
    return {
      // ...toRefs(state),
      ...itemList,
      // ...methods,
      getCodeLabel,
      getCodeColor,
    }
  },

}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.delLine{
  z-index: 999;
  position: absolute;

  height: 1px;
  line-height: 1px;
  border-top: 1px solid orange !important;
  transform: rotate(0.0deg); /*倾斜度*/
  display: none;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
