<template>

  <div>

    <!-- Table Container Card -->

    <b-card>
      <div>
        <b-table
            sticky-header
            noCollapse
            ref="refListTable"
            class="position-relative"
            :items="searchList"
            responsive
            :fields="tableColumns"
            primary-key="id"
            :sort-by.sync="orderBy"
            show-empty
            empty-text="未找到记录"
            :sort-desc.sync="isSortDirDesc"
            :tbody-tr-class="rowClass"
        >
          <!--                {{test(row.item.order_id)}}-->
          <template #cell(ext)="data">
            {{ data.item.ext.code ? data.item.ext.code : '' }}
          </template>

          <template #cell(name)="data">
            {{data.index+1}}
            [#{{ data.item.product_id }}]{{ data.item.name }}{{ data.item.is_gift === 1 ? "(赠)" : "" }}
            <feather-icon
                icon="CpuIcon"
                size="21"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="success"
                @click="function () {
                      stockOccupied.warehouse_id=data.item.warehouse_id
                      stockOccupied.product_id=data.item.product_id
                      stockOccupied.stock_id=data.item.stock_id
                      $refs['modal-stock-lock'].show()
                      $forceUpdate()
                      }"
            />
          </template>

          <template #cell(tax_rate)="data">
            {{ getCodeLabel("tax_rate", data.item.tax_rate) }}
          </template>

          <template #cell(production_date)="data">
            {{ toMonth(data.item.ext.productionDate) }}
          </template>

          <template #cell(salesreturnqty)="data">
            <b-badge
                pill
                :variant="`light-warning`"
            >
              {{ data.item.ext.salesreturnqty }}
            </b-badge>
          </template>

          <template #cell(salesreturnqty1)="data">
            <b-badge
                pill
                :variant="`light-warning`"
            >
              {{ data.item.ext.salesreturnqty1 }}
            </b-badge>
          </template>



          <template #cell(is_gift)="data">
            <feather-icon
                icon="XIcon"
                size="21"
                v-if="data.item.is_gift==0"
            />
            <feather-icon
                icon="CheckIcon"
                size="21"
                v-else
            />
          </template>


          <template #cell(cost_price_modify)="data">
            <b-form-input
                    id="memo"
                    size="sm"
                    v-model="data.item.cost_price_modify"
                    @blur="checkQty(data.item)"
                    type="number"
                    :readonly="is_sendbi"
                    style="width:50px"
            />
          </template>

          <template #cell(cost_price_modifyed)="data">
              {{data.item.cost_price_modify===undefined?"":(Number(data.item.cost_price_modify)+Number(data.item.cost_price))}}
          </template>

          <template #cell(gross_profit_rate_modifyed)="data">
            <div  v-if="data.item.is_gift===0">
              {{data.item.cost_price_modify===undefined?"":Math.round((Number(data.item.product_cost_tax)-(Number(data.item.cost_price_modify)+Number(data.item.cost_price)))/Number(data.item.product_cost_tax)*10000)/100.0000+"%"}}
            </div>
            <div  v-if="data.item.is_gift===1">
              {{0}}
            </div>
          </template>



          <!--库存转态-->
          <template #cell(status)="data">
            <b-badge
                pill
                :variant="`light-${getCodeColor('stock_status', data.item.status)}`"
            >
              {{ getCodeLabel("stock_status", data.item.status) }}
            </b-badge>
          </template>

          <!--毛利率  当售价为0时，成本价不为0时，列表明细毛利率修改为-100%。-->
          <template #cell(gross_profit_rate)="data">
            <div  v-if="data.item.product_cost===0&&data.item.cost_price!==0">
                -100%
            </div>
            <div v-else>
              {{ data.item.ext.gross_profit_rate ? data.item.ext.gross_profit_rate : '' }}
            </div>

          </template>

          <template #cell(salesCost)="data">
            {{ isNumber(data.item.ext.salesCost )}}
          </template>

          <template #cell(salesCostGpr)="data">
            {{ data.item.ext.salesCostGpr }}
          </template>


          <template #cell(purchaseCost)="data">
            {{ isNumber(data.item.ext.purchaseCost )}}
          </template>

          <template #cell(purchaseCostGpr)="data">
            {{ data.item.ext.purchaseCostGpr }}
          </template>

          <template #cell(stock_id)="data">
            <b-link
                :to="{ name: 'apps-purchaseorder-list', query: {order_id: data.item.ext.purchaseOrderId} }"
                target="_blank"
            >
              {{ data.item.stock_id }}
            </b-link>
          </template>

          <template #cell(purchaseTeam)="data">
            {{ getCodeLabel('purchase_team', data.item.purchase_team_id) }}
          </template>

        </b-table>

        <b-modal
            id="modal-stock-lock"
            ref="modal-stock-lock"
            no-close-on-backdrop
            cancel-title="取消"
            centered
            size="xl"
            title="锁库单据"
        >
          <stock-occupied-list
              :warehouse_id="stockOccupied.warehouse_id"
              :product_id="stockOccupied.product_id"
              :stock_id="stockOccupied.stock_id"
          >

          </stock-occupied-list>
        </b-modal>

      </div>
    </b-card>

  </div>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BFormCheckbox, BModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted, reactive, toRefs } from '@vue/composition-api'
import mainTableItemUseList from './mainTableItemUseList'
import salesorderitemStore from './salesorderitemStore'
import {avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor,isNumber,toMonth} from '@core/utils/filter'
import Ripple from "vue-ripple-directive";
import {useToast} from "vue-toastification/composition";
import StockOccupiedList from "@/views/apps/stockoccupied/StockOccupiedList.vue";
export default {
  components: {
    StockOccupiedList,
    BModal,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    order_id: {
      type: Number,
      default: 0
    },
    is_sendbi: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      stockOccupied:{},
    }
  },
  methods:{
    rowClass(item, type) {
      if (!item || type !== 'row') { return }
      if (item.qty>item.ext.qty-item.ext.usedStock&&item.ext.status!=2) {
        return  'table-danger'}
    },
  },
  setup(props) {
    // Register module
    if (!store.hasModule('salesorderitem')) store.registerModule('salesorderitem', salesorderitemStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('salesorderitem')) store.unregisterModule('salesorderitem')
    })
    const toast =  useToast()

    const
      itemList
    = mainTableItemUseList({
      order_id: props.order_id,
    })

    const checkQty = function(item) {
      if(!props.is_sendbi){
        store.dispatch('salesorderitem/modifyCostPrice', {id:item.item_id,price:item.cost_price_modify}).then(res => {
          toast.success('数据已保存!')
        })
      }
    }



    // const state = reactive({
    //   curRow:undefined,
    //   refundModal:undefined,
    // })
    //
    // const methods = {
    //   refund(row){
    //     //深拷贝
    //     state.curRow = { ...row }
    //     state.refundModal.show()
    //   },
    //   closeModalRefund(){
    //     state.refundModal.hide()
    //   }
    // }
    return {
      // ...toRefs(state),
      ...itemList,
      // ...methods,
      getCodeLabel,
      getCodeColor,
      checkQty,
      isNumber,
      toMonth
    }
  },

}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.delLine{
  z-index: 999;
  position: absolute;

  height: 1px;
  line-height: 1px;
  border-top: 1px solid orange !important;
  transform: rotate(0.0deg); /*倾斜度*/
  display: none;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
