import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getCodeLabel } from '@core/utils/filter'

export default function mainTableItemUseList(config) {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {key: 'ext', label: '商品69码'},
    {key: 'name', label: '名称'},
    {key: 'warehouse_name', label: '仓库'},
    {key: 'product_cost_tax', label: '含税售价', sortable: true },
    {key: 'qty', label: '售卖数量', sortable: true },
    {key: 'salesreturnqty', label: '退货数量'},
    {key: 'subtotal', label: '价税合计', sortable: true },
  ]

  const start = ref(1)
  const limit = ref(1000)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('')
  const isSortDirDesc = ref(true)
  const isTrue = ref(false)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })


  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    store
      .dispatch('salesorderitem/itemSumList', {
        id : config.order_id,
        order_by: orderBy.value,
        order_desc: isSortDirDesc.value === true ? 'desc':'',
      })
      .then(response => {
        const data = response.data.data
        callback(data)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '销售单明细列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,

    refetchData,
  }
}
